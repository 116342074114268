const Main = () => {
  return (
    <div className="maincontainer">
      <img
        id="profileimage"
        src="/Headshot.jpg"
        alt="placeholder profile shot"
      />
      <div id="paragraphdiv">
        <p className="content_text">Hello, and welcome to my website.</p>
        <p className="content_text">
          I am an RICS chartered surveyor and Registered Valuer based in
          Manchester, UK and operating across the north west of England and
          north Wales.
        </p>
        <p className="content_text">
          I have extensive experience in undertaking commercial real estate
          valuations across a variety of asset classes including, but not
          limited to, retail, industrial, offices and development land. I have a
          particular focus on the valuation of prime high value retail assets
          across all North West England in-town and out-of-town retail markets
          with substantial experience in this field. My clients have included
          banks, REITs, publicly traded companies, private individuals and
          public sector bodies, amongst others. My work has encompassed
          valuation purposes such as loan security, purchase and sale reports,
          tax and probate reviews as well as repeat monthly and quarterly fund
          valuations.
        </p>
        <p className="content_text">
          I qualified as an MRICS chartered surveyor and Registered Valuer in
          July 2022 after commencing work in the Cushman & Wakefield valuation
          team in November 2021. I was promoted to Senior Surveyor in July 2024.{" "}
        </p>
      </div>
    </div>
  );
};

export default Main;
