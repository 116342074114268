import { Link } from "react-router-dom";
const Nav = () => {
  return (
    <div className="nav_bottom">
      <div id="navlinks">
        <Link to="/" className="nav_link">
          <p>Home</p>
        </Link>
      </div>
      <p id="copyright">© Nathan George Murray</p>
    </div>
  );
};

export default Nav;
