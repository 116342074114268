import "./App.css";
import Header from "./components/Header";
import Main from "./components/main";
import Nav from "./components/nav";


import { Routes, Route } from "react-router-dom";
function App() {
  return (
    <div className="App">
      <div className="App2">
      <div className="content">
        <Header />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/contact" element={<Main />} />
        </Routes>
      </div>
      <Nav />
      </div>
    </div>
  );
}

export default App;
